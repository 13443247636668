import React, {PureComponent, Fragment} from 'react';
import './Message.scss';
import CustomCheckBox from "../CustomCheckBox";
import {inject, observer} from "mobx-react";
import Interweave from 'interweave';

@inject('Store')
@observer
class Message extends PureComponent {

	state = {
		visible: true,
		agree: false,
	}

	onHandleClick = () => {
		this.setState({visible: false})
	}

	onHandleChange = (e) => {
		this.setState({...this.state, ...e})
	}

	exitClick = () => {
		window.location.replace('https://thyreo.ru/');
	};

	render() {
		const {Store} = this.props;
		return (
			<Fragment>
				<div className={`message_box ${this.state.visible ? 'active' : ''}`}>
					<div className="title">{Store.content.zagolovok_modalnogo_okna}</div>
					<div className="content">
						<Interweave content={Store.content.tekst_modalnogo_okna}/>
						<CustomCheckBox
							name="agree"
							checked={this.state.agree}
							title="Я подтверждаю, что являюсь специалистом здравоохранения - медицинским работником"
							onClick={this.onHandleChange}
						/>
						<div className="buttons">
							<button disabled={!this.state.agree} onClick={this.onHandleClick} className="white_button">Войти</button>
							<button onClick={() => this.exitClick()} className="border_button">Вернуться</button>
						</div>
					</div>
				</div>
				{/* <div className={`message_box_overlay ${this.state.visible ? 'active' : ''}`}/> */}
			</Fragment>
		);
	}
}

export default Message;
