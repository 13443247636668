import React, {PureComponent, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import './App.scss';
import Footer from "../Footer";
import Header from "../Header";
import About from "../About";
import Composition from "../Composition";
import Research from "../Research";
import Instruction from "../Instruction";
import Message from "../Message";
import Message2 from "../Message2";
import MediaQuery from 'react-responsive'
import HeaderMobile from "../HeaderMobile";

@inject('Store')
@observer
class Main extends PureComponent {

	componentDidMount() {
		const {Store} = this.props;
		Store.GET('pages/89').then(r => Store.content = r);
		Store.GET('pages/41').then(r => Store.footer = r);
		Store.GET('pages/333').then(r => Store.privacy = r);
		Store.GET('pages/340').then(r => Store.terms = r);
	}

	render() {
		const {Store} = this.props;
		return (
			Store.content ? (
				<Fragment>
					<Message2 />
					<Message />

					<MediaQuery maxWidth={768}>
						<HeaderMobile/>
					</MediaQuery>

					<MediaQuery minWidth={769}>
						<Header/>
					</MediaQuery>

					<About/>
					<Composition
						modifier="composition_one"
						padding="67px 0 130px 0"
						background={Store.content.fon}
						background_line="linear-gradient(180deg, #C97FD4 0%, #90529B 100%)"
						title={Store.content.zagolovok_preimushhestva}
						title_header="h2"
						array={Store.content.preimushhestva_graph}
					/>
					<Composition
						modifier="composition_two"
						padding="0 0 130px 0"
						background={Store.content.fon2}
						background_line="#6D3EA8"
						title={Store.content.zagolovok_sostava}
						title_header="h3"
						array={Store.content.preimushhestva_graph_sostav}
					/>
					<Composition
						modifier="composition_three"
						padding="0 0 130px 0"
						background={Store.content.fon3}
						background_line="#CC82D7"
						title={Store.content.primenenie_zagolovok}
						title_header="h3"
						array={Store.content.primenenia_s_graph}
					/>
					<Research/>
					<Instruction/>
					{Store.footer ? <Footer/> : null}
				</Fragment>
			) : null
		);
	}
}

export default Main;
