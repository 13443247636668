import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {Provider} from "mobx-react";
import Store from './store/Store';

import TagManager from 'react-gtm-module'
import { YMInitializer } from 'react-yandex-metrika';

const tagManagerArgs = {
    gtmId: 'GTM-TBS4JN5'
}

TagManager.initialize(tagManagerArgs)

const Root = (
  <Provider Store={Store}>
    <App />
    <YMInitializer accounts={[67768795]} options={{clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true}} />
  </Provider>
);

ReactDOM.render(Root, document.getElementById('root'));
