import React, {PureComponent} from 'react';
import ReactDOM from "react-dom";
import CenterBlock from "../CenterBlock";
import './About.scss';
import {inject, observer} from "mobx-react";
import { Player } from 'video-react';
import ModalBlock from "../ModalBlock";

@inject('Store')
@observer
class About extends PureComponent {

	state = {
		visible: false,
	}

	onHandleClick = () => {
		this.setState({...this.state, visible: !this.state.visible})
	}

	render() {
		const {Store} = this.props;
		return (
			<section className="about_block">
				{
					this.state.visible &&
					ReactDOM.createPortal (
						<ModalBlock visible={this.state.visible} onClick={this.onHandleClick}>
							<Player
								playsInline={true}
								autoPlay={true}
								poster={Store.content.kartinka_video}
								src={Store.content.video_v_bloke}
							/>
						</ModalBlock>, document.getElementById('modal')
					)
				}
				<CenterBlock>
					<h2>{Store.content.zagolovok_bloka}</h2>
					<div className="about_position">
						<div className="about_position_block">
							<div className="title">{Store.content.podzagolovok_bloka}</div>
							<div className="content">{Store.content.opisanie_bloka}</div>
							<div className="content_icon">
								<img loading="lazy" width={552} height={181} src={Store.content.kartinka_pod_opisaniem_bloka} alt={Store.content.podzagolovok_bloka}/>
							</div>
						</div>
						<div className="about_position_block about_video_player_button" onClick={this.onHandleClick}>
							<img loading="lazy" width={610} height={327} src={Store.content.kartinka_video} alt="Video player"/>
						</div>
					</div>
				</CenterBlock>
			</section>
		);
	}
}

export default About;
