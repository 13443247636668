import React, {PureComponent, Fragment} from 'react';
import './HeaderMobile.scss';
import {inject, observer} from "mobx-react";
import Interweave from "interweave";

@inject('Store')
@observer
class HeaderMobile extends PureComponent {

	state = {
		visible: false
	}

	onHandleClick = () => {
		this.setState({...this.state, visible: true})
	}

	cancelExit = () => {
		this.setState({...this.state, visible: false})
	}

	onHandleSubmit = () => {
		window.location.replace('https://www.thyreo.ru/');
	}

	render() {
		const {Store} = this.props;
		const style = {
			backgroundImage: `url(${Store.content.kartinka_fona})`
		}
		return (
			<section className="header_mobile">
				<Fragment>
					<div className={`message_box ${this.state.visible ? 'active' : ''}`}>
						<div className="title">Вы покидаете l-thyroxin.ru и переходите на сайт thyreo.ru</div>
						<div className="buttons">
							<button onClick={this.onHandleSubmit} className="white_button">Перейти</button>
						</div>
					</div>
					<div onClick={this.cancelExit} className={`message_box_overlay ${this.state.visible ? 'active' : ''}`}/>
				</Fragment>

				<div style={style} className="header_mobile_top">
					<div className="header_logo_position_mob">
						<div className="logo"><img loading="lazy" width={167} height={27} src={Store.content.logotip} alt={Store.content.nazvanie_produkta}/></div>
						<div className="button_link_mob"><span onClick={this.onHandleClick}>{Store.content.nazvanie_knopki1}</span></div>
						<div className="button_link_mob button_link_mob2"><a href={Store.content.information_letter} rel="noopener noreferrer" target="_blank">{Store.content.nazvanie_knopki2}</a></div>
					</div>
				</div>
				<div className="header_mobile_bottom">
					<div className="header_mobile_product">
						<picture>
								<source srcSet={'https://www.l-thyroxin.ru/admin/wp-content/uploads/2020/06/product-pic-1-mobile.png'} media="(max-width: 400px)" />
								<img width={552} height={415} src={Store.content.kartinka_produkta} loading="lazy" alt=""/>
						</picture>
					</div>
					<div className="header_mobile_product_title">
						<h1>{Store.content.nazvanie_produkta}</h1>
						<span>{Store.content.proizvoditel}</span>
					</div>
					<div className="header_mobile_product_description"><Interweave content={Store.content.opisanie_prodkuta_v_shapke} /></div>
				</div>
			</section>
		);
	}
}

export default HeaderMobile;
