import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import Interweave from 'interweave';
import './CompositionItem.scss';

class CompositionItem extends PureComponent {
	render() {
		return (
			<div className={ this.props.center.length ? 'composition_item composition_item_wrap' : 'composition_item'}>
				<div className="title"><Interweave content={this.props.title} /></div>
				<div className="composition_item_position">
					{
						this.props.center.length ? (
							<Fragment>
								<div className="composition_item_center center"><Interweave content={this.props.center} /></div>
							</Fragment>
						) : null
					}
					{
						this.props.right.length ? (
							<Fragment>
								<div className="composition_item_left"><Interweave content={this.props.left} /></div>
								<div className="composition_item_right"><Interweave content={this.props.right} /></div>
							</Fragment>
						) : (
							<Fragment>
								<div className="composition_item_left center"><Interweave content={this.props.left} /></div>
							</Fragment>
						)
					}
				</div>
			</div>
		);
	}
}

CompositionItem.propTypes = {
	title: PropTypes.string.isRequired,
	center: PropTypes.string,
	left: PropTypes.string.isRequired,
	right: PropTypes.string.isRequired,
};

CompositionItem.defaultProps = {
	right: '',
	center: '',
}

export default CompositionItem;
