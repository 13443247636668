import React, {PureComponent, Fragment} from 'react';
import {Link} from "react-router-dom";
import {inject, observer} from "mobx-react";
import './Footer.scss';
import Interweave from 'interweave';
import CookieConsent from "react-cookie-consent";
import CenterBlock from "../CenterBlock";
import medFooter from './pic/med-footer.jpg';

@inject('Store')
@observer
class Footer extends PureComponent {

	state = {
		visible: false
	}

	onHandleClick = () => {
		this.setState({...this.state, visible: true})
	}

	cancelExit = () => {
		this.setState({...this.state, visible: false})
	}

	onHandleSubmit = () => {
		window.location.replace('https://www.berlin-chemie.ru/');
	}

	render() {
		const {Store} = this.props;
		return (
			<CenterBlock>
				<Fragment>
					<div className={`message_box ${this.state.visible ? 'active' : ''}`}>
						<div className="title">Вы покидаете l-thyroxin.ru и переходите на сайт berlin‑chemie.ru</div>
						<div className="buttons">
							<button onClick={this.onHandleSubmit} className="white_button">Перейти</button>
						</div>
					</div>
					<div onClick={this.cancelExit} className={`message_box_overlay ${this.state.visible ? 'active' : ''}`}/>
				</Fragment>
				<footer>
					<div className="footer_block_logo">
						<img loading="lazy" onClick={this.onHandleClick} width={339} height={55} src={Store.footer.logotip_v_futere} alt="Берлин-Хеми/А.Менарини"/>
					</div>
					<div className="footer_block_content">
						<Interweave content={Store.footer.spisok_prof} />
						<Link to="/privacy">Политика конфиденциальности</Link>&nbsp;<Link to="/terms">Условия использования</Link>
					</div>
				</footer>
				<div className="med_footer"><img loading="lazy" src={medFooter} width={1280} height={68} alt="Берлин-Хеми/А.Менарини"/></div>
				<CookieConsent
						location="bottom"
						buttonText="Принять"
						cookieName="lThyroxinCookie"
						style={{ background: "#e9e2f2", color: "#272727" }}
						buttonStyle={{ color: "#922358", fontSize: "20px", background: "#ffffff", fontWeight: "bold", borderRadius: "76px" }}
						expires={7}
					>Мы используем файлы cookie, чтобы улучшить работу сайта (см. подробнее в нашей <Link to="/privacy">Политике конфиденциальности</Link> и <Link to="/terms">Условиях использования</Link>). Продолжая использовать сайт, вы соглашаетесь с этим.</CookieConsent>
			</CenterBlock>
		);
	}
}

export default Footer;
