import React, {PureComponent, Fragment} from 'react';
import {inject, observer} from "mobx-react";

@inject('Store')
@observer
class Message2 extends PureComponent {
	state = {
		visible: true,
	}

	onHandleClick = () => {
		this.setState({visible: false})
	}

	render() {
		const {Store} = this.props;
		return (
			<Fragment>
				<div className={`message_box message_box2 ${this.state.visible ? 'active' : ''}`}>
					<div className="content">
						<p>Уважаемые специалисты здравоохранения!</p>
						<p>Компания ООО &laquo;Берлин-Хеми/А. Менарини&raquo; информирует вас об&nbsp;изменении состава вспомогательных веществ, условий и&nbsp;срока хранения, а&nbsp;также дизайна упаковок препаратов, содержащих в&nbsp;качестве действующего вещества левотироксин натрия, а&nbsp;именно:</p>
						<p>L-Тироксин 50&nbsp;Берлин-Хеми, тб.&nbsp;50&nbsp;мкг (блистеры) &#8470;&nbsp;50<br />
						L-Тироксин 75&nbsp;Берлин-Хеми, тб.&nbsp;75&nbsp;мкг (блистеры) &#8470;&nbsp;100<br />
						L-Тироксин 100 Берлин-Хеми, тб.&nbsp;100 мкг (блистеры) &#8470;&nbsp;50<br />
						L-Тироксин 100 Берлин-Хеми, тб.&nbsp;100 мкг (блистеры) &#8470;&nbsp;100<br />
						L-Тироксин 125 Берлин-Хеми, тб.&nbsp;125 мкг (блистеры) &#8470;&nbsp;100<br />
						L-Тироксин 150 Берлин-Хеми, тб.&nbsp;150 мкг (блистеры) &#8470;&nbsp;100</p>
						<p>Обращаем внимание, что в&nbsp;аптеках будут доступны как новые упаковки препарата L-Тироксин Берлин-Хеми, так и&nbsp;прежние до&nbsp;истечения срока их&nbsp;годности.</p>
						<p>ООО &laquo;Берлин-Хеми/А.Менарини&raquo; информирует Вас, что качество поставляемых препаратов остается неизменным.</p>
						<div className="buttons">
							<button onClick={this.onHandleClick} className="border_button">Закрыть</button>
						</div>
					</div>
				</div>
				<div className={`message_box_overlay ${this.state.visible ? 'active' : ''}`}/>
			</Fragment>
		);
	}
}

export default Message2;
