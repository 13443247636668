import {observable, action} from "mobx";
import axios from "axios";

axios.defaults.baseURL = 'https://l-thyroxin.ru/admin/wp-json/acf/v3';
axios.defaults.headers.common['Content-Type'] = 'application/json, multipart/form-data';

class Store {

	@observable content = null;
	@observable footer = null;
	@observable privacy = null;
	@observable terms = null;

	@action GET = async (slug) => {
		const url = `${axios.defaults.baseURL}/${slug}`;
		const request = await axios.get(url);
		if (request.status === 200) return request.data.acf
	}
}

const store = new Store();
export default store;