import React, {PureComponent, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import CenterBlock from "../CenterBlock";
import './Header.scss';
import Interweave from 'interweave';

@inject('Store')
@observer
class Header extends PureComponent {

	state = {
		visible: false
	}

	onHandleClick = () => {
		this.setState({...this.state, visible: true})
	}

	cancelExit = () => {
		this.setState({...this.state, visible: false})
	}

	onHandleSubmit = () => {
		window.location.replace('https://www.thyreo.ru/');
	}

	render() {
		const {Store} = this.props;
		const style = {
			backgroundImage: `url(${Store.content.kartinka_fona})`
		}
		return (
			<section style={style} className="header_block">
				<Fragment>
					<div className={`message_box ${this.state.visible ? 'active' : ''}`}>
						<div className="title">Вы покидаете l-thyroxin.ru и переходите на сайт thyreo.ru</div>
						<div className="buttons">
							<button onClick={this.onHandleSubmit} className="white_button">Перейти</button>
						</div>
					</div>
					<div onClick={this.cancelExit} className={`message_box_overlay ${this.state.visible ? 'active' : ''}`}/>
				</Fragment>

				<CenterBlock>
					<div className="header_logo_position">
						<div className="logo"><img loading="lazy" width={167} height={27} src={Store.content.logotip} alt={Store.content.nazvanie_produkta}/></div>
						<div className="button_link"><span onClick={this.onHandleClick}>{Store.content.nazvanie_knopki1}</span></div>
						<div className="button_link button_link2"><a href={Store.content.information_letter} rel="noopener noreferrer" target="_blank">{Store.content.nazvanie_knopki2}</a></div>
					</div>
				</CenterBlock>
				<div className="header_block_line">
					<CenterBlock>
						<div className="header_position_content">
							<div className="header_product"><img loading="lazy" width={552} height={415} src={Store.content.kartinka_produkta} alt=""/></div>
							<div className="header_product_title">
								<h1>{Store.content.nazvanie_produkta}</h1>
								<span>{Store.content.proizvoditel}</span>
							</div>
							<div className="header_product_description"><Interweave content={Store.content.opisanie_prodkuta_v_shapke} /></div>
						</div>
					</CenterBlock>
				</div>
			</section>
		);
	}
}

export default Header;
