import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './ModalBlock.scss';
import closeModal from './pic/close-modal.svg'

class ModalBlock extends PureComponent {

	state = {
		visible: false
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({...this.state, visible: true})
		}, 100)

		if (!this.props.scroll) {
			document.body.classList.add("overflow_hidden");
		}
	}

	componentWillUnmount() {
		if (!this.props.scroll) {
			document.body.classList.remove("overflow_hidden");
		}
	}

	onHandleClick = () => {
		this.setState({...this.state.visible, visible: false})
		setTimeout(() => {
			this.props.onClick()
		}, 300)
		if (!this.props.scroll) {
			document.body.classList.remove("overflow_hidden");
		}
	}

	render() {
		return (
			<div className={`modal_position ${this.state.visible ? 'active' : ''}`}>
				<div className={`modal_block ${this.state.visible ? 'active' : ''}`}>
					<button className="close_modal" onClick={this.onHandleClick}>
						<img width={15} height={15} loading="lazy" src={closeModal} alt="Закрыть окно"/>
					</button>
					<div className="modal_block_text">
						{this.props.children}
					</div>
				</div>
				<div className="modal_overlay" onClick={this.onHandleClick}/>
			</div>
		);
	}
}

ModalBlock.propTypes = {
	visible: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
	scroll: PropTypes.bool,
};

export default ModalBlock;
