import React, {PureComponent, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import Interweave from 'interweave';
import CenterBlock from "../CenterBlock";
import Footer from "../Footer";
import './Terms.scss';

@inject('Store')
@observer
class Terms extends PureComponent {

	componentDidMount() {
		const {Store} = this.props;
		Store.GET('pages/89').then(r => Store.content = r);
		Store.GET('pages/41').then(r => Store.footer = r);
		Store.GET('pages/333').then(r => Store.privacy = r);
		Store.GET('pages/340').then(r => Store.terms = r);
	}

	render() {
		const {Store} = this.props;
		return (
			Store.content ? (
				<Fragment>
					<CenterBlock>
						<div style={{padding: "30px 20px 20px", boxSizing: "border-box"}}>
							<Link to="/"><img loading="lazy" width={167} height={27} src={Store.content.logotip} alt={Store.content.nazvanie_produkta}/></Link>
						</div>
						<div style={{background: "#6d3ea8", height: "40px"}}></div>
						{Store.terms ? <div className='terms-content'><Interweave content={Store.terms.razmetka} /></div> : null}
					</CenterBlock>
					{Store.footer ? <Footer/> : null}
				</Fragment>
			) : null
		);
	}
}

export default Terms;