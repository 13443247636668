import React, {PureComponent} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Main from "../Main";
import Privacy from "../Privacy";
import Terms from "../Terms";
import ScrollToTop from "../ScrollToTop";
import './App.scss';

class App extends PureComponent {
	render() {
		return (
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route index path="/" element={<Main />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="*" element={<Navigate to ="/" />}/>
				</Routes>
			</BrowserRouter>
		);
	}
}

export default App;